<template>
  <section>
    <div id="immagine">
      <div
        v-if="showImg "
        class="child "
      >
        <img
          id="img"
          :src="imgUrl"
          class=""
          alt="Immagine"
          rel="preload"
        >
      </div>
    </div>
  </section>
</template>
<script>

export default {

  data() {
	  return {
		showImg: false,
  		imgUrl: '',
	  	showImg2: false,
  		imgUrl2: ''
	  }
  },
  mounted() {
		const img = new Image();

    img.onload = () => {
      this.imgUrl = img.src;
      this.showImg = true;
    }
if (window.innerWidth < 768) {
	img.src = require("../assets/lugano_cellulare.webp");
} else {
	img.src = require("../assets/lugano.webp");
}
  }
}
</script>
<style lang="scss" scoped>
#container {
  width: 100%;
  max-height: 800px;
  padding-bottom: 10px;
  display: flex;
	justify-content: center;


}

.child {
  display: flex;
  width: 100%;
  justify-content: center;
  height: inherit;

}

#img2 {
  align-items: stretch;
  height: 100%;
  width: 100%;

}
#img {
  align-items: stretch;
  height: 100%;
  width: 100%;
}
#serviziTestoIniziale{
text-align: center;
justify-content: center;
font-size: 18px;
}
#serviziLista_{
width: 40%;
margin-top: 20px;
text-align: initial;
}
#containerServizi{
justify-content: center;
line-height: 1.75em;
font-size: 18px;
}
</style>