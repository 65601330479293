<template>
  <div class="container px-4 py-5">
    <div class="title">
      <h1 class="pb-2 border-bottom lh-1 mb-3 ">
        {{ $t('contatti.contatti_') }}
      </h1>
    </div>
    <div class="contatti">
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class=" text-center mb-5 text py-2">
              <p>{{ $t("contatti.messaggio_1") }}</p>
              <p>{{ $t("contatti.messaggio_2") }}</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-12">
              <div class="wrapper">
                <div class="row no-gutters mb-5 ">
                  <!--Form-->
                  <div class=" col-12 mb-14 form">
                    <div
                      id="form"
                      class="shadow contact-wrap w-100 p-md-6 p-4"
                    >
                      <div>
                        <h3 class="mb-4">
                          {{ $t("contatti.contattaci") }}
                        </h3>
                      </div>
                      <form
                        id="contactForm"
                        ref="anyName"
                        name="contactForm"
                        class="contactForm"
                        novalidate="novalidate"
                        autocomplete="off"
                        role="form"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label
                                class="label"
                                for="name"
                              >{{ $t('contatti.nome_copleto') }}</label>
                              <input
                                id="name"
                                v-model="name"
                                :class="{ 'is-invalid': nameError, 'is-valid': nameCorrect}"
                                type="text"
                                class="form-control"
                                name="name"
                                :placeholder="$t('contatti.nome_copleto')"
                                required
                                data-error="Firstname is required."
                              >
                              <div
                                v-if="notice[0]"
                                id="feedback-1"
                                :class="{ 'invalid-feedback': invalidfeedback1, 'valid-feedback': validFeedback1 }"
                              >
                                {{ notice[0].message }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label
                                class="label"
                                for="email"
                              >{{ $t('contatti.email') }}</label>
                              <input
                                id="email"
                                v-model="email"
                                :class="{ 'is-invalid': emailError, 'is-valid': emailCorrect }"
                                type="email"
                                class="form-control"
                                name="email"
                                :placeholder="$t('contatti.email')"
                                required
                              >
                              <div
                                v-if="notice[1]"
                                id="feedback-2"
                                :class="{ 'invalid-feedback': invalidfeedback2, 'valid-feedback': validFeedback2 }"
                              >
                                {{ notice[1].message }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label
                                class="label"
                                for="subject"
                              >{{ $t('contatti.oggetto') }}</label>
                              <input
                                id="subject"
                                ref="subject"
                                v-model="subject"
                                :class="{ 'is-invalid': subjectError, 'is-valid': subjectCorrect }"
                                type="text"
                                class="form-control"
                                name="subject"
                                :placeholder="$t('contatti.oggetto')"
                                required
                              >
                              <div
                                v-if="notice[2]"
                                id="feedback-3"
                                :class="{ 'invalid-feedback': invalidfeedback3, 'valid-feedback': validFeedback3 }"
                              >
                                {{ notice[2].message }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label
                                class="label"
                                for="#"
                              >{{ $t('contatti.messaggio') }}</label>
                              <textarea
                                id="message"
                                v-model="message"
                                :class="{ 'is-invalid': messageError, 'is-valid': messageCorrect }"
                                name="message"
                                class="form-control"
                                cols="30"
                                rows="4"
                                :placeholder="$t('contatti.messaggio')"
                                required
                              />
                              <div
                                v-if="notice[3]"
                                id="feedback-4"
                                :class="{ 'invalid-feedback': invalidfeedback4, 'valid-feedback': validFeedback4 }"
                              >
                                {{ notice[3].message }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 buttonSend">
                            <div class="form-group">
                              <input
                                :value="$t('contatti.send_message')"
                                class="btn btn-primary"
                                @click="sendEmail"
                              >
                              <div class="submitting" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div
                              id="messageSubmit"
                              class="form-group"
                            >
                              <div
                                v-if="submitting[0]"
                                id="messageSubmitted"
                                :class="{ 'invalid-feedback': invalidfeedback5, 'valid-feedback': validFeedback5 }"
                              >
                                <h4>{{ submitting[0].message }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col d-flex align-content-center flex-wrap justify-content-center">
                    <div>
                      <div class="p-2 via">
                        <div class="dbox w-100 text-center">
                          <div
                            class="
					  shadow
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="white"
                              class="bi bi-geo-alt-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                            </svg>
                            <i class="bi bi-geo-alt-fill" />
                          </div>
                          <div class="text">
                            <p>
                              <span> {{ $t("contatti.via") }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="p-2 phone">
                        <div class="dbox w-100 text-center">
                          <div
                            class="
					  shadow
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="white"
                              class="bi bi-telephone-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                              />
                            </svg>
                          </div>
                          <div class="text">
                            <p>
                              <span />
                              <a href="tel://+41786312818">+41 786312818</a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="p-2 email">
                        <div class="dbox w-100 text-center">
                          <div
                            class="
					  shadow
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="white"
                              class="bi bi-envelope-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                            </svg>
                          </div>
                          <div class="text">
                            <p>
                              <span />
                              <a href="mailto:m.vecchi140257@gmail.com">m.vecchi140257@gmail.com</a>
                              <br><a href="mailto:m.vecchi@finsuiza.ch">m.vecchi@finsuiza.ch</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Row-->
                <div class="row justify-content-center">
                  <div class="col">
                    <div class="row">
                      <!--Google map-->
                      <div class="googleMap ">
                        <div
                          id="map"
                          class="shadow"
                        >
                          <google-map />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import GoogleMap from '@/components/GoogleMap.vue';

export default {
  components: {
	GoogleMap
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });
  },
  data () {
			return {
				name: '',
				email: '',
				subject: '',
				message: '',
				nameError: false,
				emailError: false,
				subjectError: false,
				messageError: false,
				nameCorrect: false,
				emailCorrect: false,
				subjectCorrect: false,
				messageCorrect: false,
				invalidfeedback5: false,
				validFeedback5: false,
				invalidfeedback4: false,
				validFeedback4: false,
				invalidfeedback3: false,
				validFeedback3: false,
				invalidfeedback2: false,
				validFeedback2: false,
				invalidfeedback1: false,
				validFeedback1: false,
				notice: [],
				submitting: []
			}
  },
  methods: {
	 		 trackSendig () {
      			this.$gtag.event('event', 'submitMessageContatti')
    		},
			validate () {
				this.notice = [];
				var len = this.name.length;
				if (len < 5 || len > 20) {
					this.nameError = true;
					this.nameCorrect = false;
					this.invalidfeedback1 = true;
					this.validFeedback1 = false;
					this.notice.push({
						message: this.$t("contatti.nome_copleto_errore")
					});
				} else {
					this.nameError = false;
					this.nameCorrect = true;
					this.invalidfeedback1 = false;
					this.validFeedback1 = true;
					this.notice.push({
						message: 'Validated.'
					});
				}
				// email validate
				if (this.email.length < 10 || this.email.search('@') === -1) {
					this.emailError = true;
					this.emailCorrect = false;
					this.invalidfeedback2 = true;
					this.validFeedback2 = false;
					this.notice.push({
						message: this.$t("contatti.email_errore")
					});
				} else {
					this.emailError = false;
					this.emailCorrect = true;
					this.invalidfeedback2 = false;
					this.validFeedback2 = true;
					this.notice.push({
						message: 'Validated.'
					});
				}
				if (this.subject.length < 2 || this.subject.length > 20) {
					this.subjectError = true;
					this.subjectCorrect = false;
					this.invalidfeedback3 = true;
					this.validFeedback3 = false;
					this.notice.push({
						message: this.$t("contatti.oggetto_errore")
					});
				} else {
					this.subjectError = false;
					this.subjectCorrect = true;
					this.invalidfeedback3 = false;
					this.validFeedback3 = true;
					this.notice.push({
						message: 'Validated.'
					});
				}
				// comment validate
				var regex = /^[a-zA-Z0-9 .-]+$/;
				if (this.message.length < 10) {
					this.messageError = true;
					this.messageCorrect = false;
					this.invalidfeedback4 = true;
					this.validFeedback4 = false;
					this.notice.push({
						message: this.$t("contatti.messaggio_errore")
					});
				} else {
					this.messageError = false;
					this.messageCorrect = true;
					this.invalidfeedback4 = false;
					this.validFeedback4 = true;
					this.notice.push({
						message: 'Validated.'
					});
				}
			},

		sendEmail () {
			this.validate();
		if (this.validFeedback1 === true && this.validFeedback2 === true && this.validFeedback3 === true && this.validFeedback4 === true) {
        this.$loadScript("https://smtpjs.com/v3/smtp.js")
        .then(() => {
			    window.Email && window.Email.send({
                SecureToken: "cad9c7a4-50e7-449b-b6bc-bc1cbaec39e7",
                To: 'prova@finsuiza.ch',
                From: this.email,
                Subject: this.name + " - " + this.subject,
                Body: this.message
            }).then(
				this.invalidfeedback5 = false,
				this.validFeedback5 = true,
				this.submitting.push({
					message: this.$t("contatti.messaggio_messaggio_inviato")
				}),
                message => alert(message),
				this.resetForm(),
				this.trackSendig()
            );
        })
		}
		},
		resetForm () {
				this.name = '';
				this.email = '';
				this.subject = '';
				this.message = '';
				this.nameError = false;
				this.emailError = false;
				this.subjectError = false;
				this.messageError = false;
				this.nameCorrect = false;
				this.emailCorrect = false;
				this.subjectCorrect = false;
				this.messageCorrect = false;
				this.invalidfeedback4 = false;
				this.validFeedback4 = false;
				this.invalidfeedback3 = false;
				this.validFeedback3 = false;
				this.invalidfeedback2 = false;
				this.validFeedback2 = false;
				this.invalidfeedback1 = false;
				this.validFeedback1 = false;
				this.notice = [];
}
	}
}
</script>
<style>
.dbox .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #2553b8;
    margin: 0 auto;
    margin-bottom: 20px; }
.dbox .icon span {
    font-size: 20px;
    color: #fff; }
#contactForm .error {
  color: red;
  font-size: 12px; }

#contactForm .form-control {
  font-size: 16px; }

#message {
  resize: vertical; }

#form-message-warning {
  color: red; }

#form-message-success {
  color: #28a745;
  font-size: 18px;
  font-weight: 500; }

#formTitle {
  padding-bottom: 1rem;
}
#form {
  min-height: 550px;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #2553b8;
}
.form-group {
    margin-bottom: 1rem;
}
.contact-wrap {
    background: #e8edf0;
}
#map {
  width: 100%; }
  @media (max-width: 767.98px) {
    #map {
      height: 300px;
	  margin-top: 1rem
	   } }
.containerTitle
{
	height: 150px;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
  	justify-content: center;
  	align-items: center;
}
.content {
  flex: 0 0 240;
  color: white;
}
.valid-feedback{
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}
.buttonSend{
	margin-top: 3rem!important;
	justify-content: center;
	align-items: center;
	display: flex;
}
.emailText{
	justify-content: center;
	display: flex;
}
.phone{

}
.email{

}
.googleMap{
	height: 400px;
	display: flex;
}
@media (min-width: 980px) {
    .form {
	justify-content: center !important;
	align-items: center;
	display: flex;
		margin: 0 auto;
	   } }
@media (min-width: 980px){
.form {
    flex: 0 0 auto !important;
    width: 66.66666667% !important;
}}
@media (min-width: 980px){
.form {
    margin-bottom: 0 !important;
}}
@media (max-width: 992px){
.form {
    flex: 0 0 auto !important;
    width: 100% !important;
		margin-bottom: 5rem !important;

}}

</style>
