<template>
  <div id="header_">
    <header class="py-2 border-bottom  align-items-center">
      <div class=" container d-flex flex-wrap justify-content-center align-items-center">
        <a
          href="/"
          class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none"
        >
          <img
            src="Logo.webp"
            class=" img-fluid"
            alt=""
            style="max-height:120px"
          >
          <span class="fs-1 fw-bold "><h1
            id="title"
            style="
	font-weight: bold;
	font-family:GaramItcTBolIta;
	font-style: italic;
	color: #003399;
	 "
          >&nbsp;Finsuiza A.G.</h1></span>
        </a>

        <ul class="nav ms-auto ">
          <li class="nav-item">
            <localized-link
              class="nav-link link-dark px-2"
              to="/"
            >
              {{ t("header_footer.home") }}
            </localized-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link link-dark px-2"
              @click="goto('chiSiamo')"
            >
              {{ t("header_footer.chi_siamo") }}
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle link-dark px-2"
              data-bs-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              {{ t("header_footer.servizi") }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  @click="goto('consulenza')"
                >{{ $t("header_footer.consulenza") }}</a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  @click="goto('trading')"
                >{{ $t("header_footer.trading") }}</a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  @click="goto('bankAdvisor')"
                >{{ $t("header_footer.bankadvisor") }}</a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a
              class="nav-link link-dark px-2"
              @click="goto('formContatti')"
            >
              {{ t('header_footer.contatti') }}
            </a>
          </li>
          <li>
            <div class="nav__end">
              <LocaleSwitcher />
            </div>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import LocalizedLink from './LocalizedLink'
import LocaleSwitcher from "@/components/LocaleSwitcher"
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Header',
   components: { LocalizedLink, LocaleSwitcher },
  setup () {
    const { t } = useI18n({
       inheritLocale: true,
       useScope: 'local'
    })

    // Something todo ..

    return { t }
  },
  methods: {
    goto(refName) {
      var element = this.$parent.$refs[refName];
      var top = element.offsetTop-190;


      window.scrollTo(0, top);
    }
  }
})
</script>
<style lang="scss" scoped>
header{
	background-color: white;

}
.nav .nav-link, .nav-item {
    color: rgba(0, 0, 0, 0.664);
}
@media (min-width: 992px){
.navbar-expand-lg .nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}}
.navbar .nav-link {
    font-size: 1rem;
    font-weight: bold;
}
.nav .nav-link {
    transition: all .2s ease-in-out;
    outline: 0;
}
.nav {
    font-family: Arimo,sans-serif;
    letter-spacing: .050rem;
    font-weight: 700;
    line-height: 1.5;
	font-size: 1.1rem;
}
@media (min-width: 992px){
.navbar-expand-lg .nav .nav-link:before {
    transition: all .2s ease-in-out;
    position: absolute;
    content: '';
    height: .1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0;
}
}
@media (max-width: 772px){
.nav{
	font-size:1rem;
}
}

.nav-item:hover,  .nav-item > a:hover{
    color: black !important
}
.gm-ui-hover-effect{opacity:.6}.gm-ui-hover-effect:hover{opacity:1}

@font-face {
		font-display: swap;
  	font-family: 'GaramItcTBolIta';
	src: url("../assets/garamond-itc-t-bold-italic.otf") format("opentype");
    src:	url('../assets/garamond-itc-t-bold-italic.woff2') format('woff2'), /* Super Modern Browsers */
    	url('../assets/garamond-itc-t-bold-italic.woff') format('woff'), /* Pretty Modern Browsers */
}
#header_ {
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 10%);
}
@media (min-width: 1200px){
body.page-scrolled #header_ {
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 10%);
}}
</style>
