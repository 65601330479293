<template>
  <GoogleMap
    api-key="AIzaSyBH2IVC06rRHCUINUOLwq_vamuk_e5FeeI"
    style="width: 100%; height: 100%"
    :center="center"
    :zoom="15"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>

<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'

export default defineComponent({
  components: { GoogleMap, Marker },
  setup () {
    const center = { lat: 45.993604764270955, lng: 8.945772027736277 }

    return { center }
  }
})
</script>