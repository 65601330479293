<template>
  <!-- Footer -->
  <footer class="bg-dark text-center text-white">
    <!-- Copyright -->
    <div
      class="text-center p-3"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      <p> Finsuiza A.G. - Riva Antonio Caccia 10A, 6900, Lugano, Svizzera</p>
      <p>{{$t('header_footer.cap_sociale')}}: chf 100.000,00 - CHE-104.013.479</p>
      <localized-link
        to="/privacypolicy"
        class="text-decoration-none text-white"
      >
        {{ $t('header_footer.privacy_policy') }}
      </localized-link>
      /			<localized-link
        to="/cookiepolicy"
        class="text-decoration-none text-white"
      >
        {{ $t('header_footer.cookie_policy') }}
      </localized-link>
    </div>
  <!-- Copyright -->
  </footer>
<!-- Footer -->
</template>

<script>
import LocalizedLink from './LocalizedLink'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Footer',
  components: { LocalizedLink },
  setup () {
    const { t } = useI18n({
       inheritLocale: true,
       useScope: 'local'
    })

    // Something todo ..

    return { t }
  }
})
</script>
