<template>
  <div
    id="container"
    class="feature  subDiv3"
  >
    <div class=" containerBank">
      <div>
        <div class="feature-icon bg-primary bg-gradient">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-bank"
            viewBox="0 0 16 16"
          >
            <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z" />
          </svg>
        </div>
        <h2>
          {{ $t("header_footer.bankadvisor") }}
        </h2>
        <div
          id="text"
        />
        <p>
          Paragrafo di testo sotto l'intestazione per spiegare l'intestazione.
          Lo aggiungeremo con un'altra frase e probabilmente continueremo finché non avremo esaurito le parole.
        </p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>

#serviziTestoIniziale{
text-align: center;
justify-content: center;
font-size: 18px;

}
#containerServizi{
line-height: 1.75em;
font-size: 18px;
text-align: start ;
}
#serviziListaConsulenza_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#serviziLista_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#container{
	margin:2rem;

}
.active
{
background: #f9f9f9;
}
.feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
	margin-left: 1rem;
	margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #fff;
    border-radius: .75rem;
}
.containerConsulenza {

margin: 1rem;
}
.containerTrading {

margin: 1rem;

}
.containerBank {

margin: 1rem;

}
.subDiv1:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv2:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv3:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#fruttiesotici{
	margin-top: 1rem;
}
#text{
	margin-top: 1.5rem;
}
</style>