<template>
  <div
    id="container"
    class="feature subDiv2 "
  >
    <div class="containerTrading">
      <div>
        <div class="feature-icon bg-primary bg-gradient">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-box-seam"
            viewBox="0 0 16 16"
          >
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
          </svg>
        </div>
        <h2>
          {{ $t("header_footer.trading") }}
        </h2>
        <div
          id="containerTrading"
        >
          <div id="text">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
            </p>

            <div id="carbonevegetale">
              <h3>Carbone Vegetale</h3>
              <div class="clearfix">
                <img
                  class="float-start"
                  src="../../assets/carbonevegetale.webp"
                  alt="Immagine"
                  width="200"
                  height="230"
                >
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
              </div>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
            </div>
          </div>
          <div
            id="fruttiesotici"
            class=""
          >
            <h3>Frutti Esotici</h3>
            <div id="text">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>

              <div class="clearfix">
                <img
                  class="float-end"
                  src="../../assets/frutta_esotica.webp"
                  alt="Immagine"
                  width="300"
                  height="200"
                >
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
                </p>
              </div>

              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>

#serviziTestoIniziale{
text-align: center;
justify-content: center;
font-size: 18px;

}
#containerServizi{
line-height: 1.75em;
font-size: 18px;
text-align: start ;
}
#serviziListaConsulenza_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#serviziLista_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#container{
	margin:2rem;

}
.active
{
background: #f9f9f9;
}
.feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
	margin-left: 1rem;
	margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #fff;
    border-radius: .75rem;
}
.containerConsulenza {

margin: 1rem;
}
.containerTrading {

margin: 1rem;

}
.containerBank {

margin: 1rem;

}
.subDiv1:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv2:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv3:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#fruttiesotici{
	margin-top: 1rem;
}
#text{
	margin-top: 1.5rem;
}
</style>