<template>
  <div class="container col-xxl-8 px-4 py-5">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5 justify-content-center">
      <div class="col-10 col-sm-8 col-lg-6 align-items-center justify-content-center">
        <img
          src="../assets/immagine_finsuiza.webp"
          class="d-block mx-lg-auto img-fluid"
          alt="Immagine"
          width="500"
          height="300"
          loading="lazy"
        >
      </div>
      <div class="col-lg-6">
        <div class="title">
          <h1 class="pb-2 border-bottom lh-1 mb-3 ">
            {{ $t('header_footer.chi_siamo') }}
          </h1>
        </div>
        <div class="py-2">
          <p class=" text">
            {{ $t('chi_siamo.parte1') }}
          </p>
          <br><p class=" text">
            {{ $t('chi_siamo.parte2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
