<template>
  <div
    id="container"
    class="feature subDiv1"
  >
    <div class="containerConsulenza">
      <div>
        <div class="feature-icon bg-primary bg-gradient">
          <svg
            id="consulting"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="currentColor"
            class="bi bi-person-circle"
          >
            <g
              id="Group_518"
              data-name="Group 518"
            >
              <path
                id="Path_757"
                data-name="Path 757"
                d="M40.5,22A1.5,1.5,0,0,1,39,20.5v-9A2.5,2.5,0,0,0,36.5,9h-15a1.5,1.5,0,0,1,0-3h15A5.5,5.5,0,0,1,42,11.5v9A1.5,1.5,0,0,1,40.5,22Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_519"
              data-name="Group 519"
            >
              <path
                id="Path_758"
                data-name="Path 758"
                d="M40.5,22a1.488,1.488,0,0,1-1.06-.44l-3.5-3.5a1.5,1.5,0,0,1,2.122-2.122l2.44,2.44,2.44-2.44a1.5,1.5,0,0,1,2.122,2.122l-3.5,3.5A1.5,1.5,0,0,1,40.5,22Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_520"
              data-name="Group 520"
            >
              <path
                id="Path_759"
                data-name="Path 759"
                d="M22.5,42h-11A5.5,5.5,0,0,1,6,36.5v-9a1.5,1.5,0,0,1,3,0v9A2.5,2.5,0,0,0,11.5,39h11a1.5,1.5,0,0,1,0,3Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_521"
              data-name="Group 521"
            >
              <path
                id="Path_760"
                data-name="Path 760"
                d="M11,32.5a1.488,1.488,0,0,1-1.06-.44L7.5,29.62,5.06,32.06a1.5,1.5,0,0,1-2.122-2.122l3.5-3.5a1.5,1.5,0,0,1,2.122,0l3.5,3.5A1.5,1.5,0,0,1,11,32.5Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_522"
              data-name="Group 522"
            >
              <path
                id="Path_761"
                data-name="Path 761"
                d="M10,10a5,5,0,1,1,5-5A5.007,5.007,0,0,1,10,10Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,10,3Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_523"
              data-name="Group 523"
            >
              <path
                id="Path_762"
                data-name="Path 762"
                d="M18.5,20A1.5,1.5,0,0,1,17,18.5v-1A2.5,2.5,0,0,0,14.5,15h-9A2.5,2.5,0,0,0,3,17.5v1a1.5,1.5,0,0,1-3,0v-1A5.5,5.5,0,0,1,5.5,12h9A5.5,5.5,0,0,1,20,17.5v1A1.5,1.5,0,0,1,18.5,20Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_524"
              data-name="Group 524"
            >
              <path
                id="Path_763"
                data-name="Path 763"
                d="M38,38a5,5,0,1,1,5-5A5.007,5.007,0,0,1,38,38Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,38,31Z"
                fill="#fff"
              />
            </g>
            <g
              id="Group_525"
              data-name="Group 525"
            >
              <path
                id="Path_764"
                data-name="Path 764"
                d="M46.5,48A1.5,1.5,0,0,1,45,46.5v-1A2.5,2.5,0,0,0,42.5,43h-9A2.5,2.5,0,0,0,31,45.5v1a1.5,1.5,0,0,1-3,0v-1A5.5,5.5,0,0,1,33.5,40h9A5.5,5.5,0,0,1,48,45.5v1A1.5,1.5,0,0,1,46.5,48Z"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <h2 id="titoloServizio">
          {{ $t("header_footer.consulenza") }}
        </h2>
        <div id="text">
          <div id="serviziTestoIniziale">
            <p>{{ $t('servizi.inizio1') }}</p>
            <p> {{ $t('servizi.inizio') }}</p>
          </div>
          <div
            id="serviziLista"
            class="d-flex justify-content-center"
          >
            <div
              id="serviziLista_"
              class="col-md-6 text"
            >
              <ul>
                <li>{{ $t('servizi.servizio1') }}</li>
                <li>{{ $t('servizi.servizio2') }}</li>
                <li>{{ $t('servizi.servizio3') }}</li>
                <li>{{ $t('servizi.servizio4') }}</li>
                <li>{{ $t('servizi.servizio5') }}</li>
                <li>{{ $t('servizi.servizio6') }}</li>
                <li>{{ $t('servizi.servizio7') }}</li>
                <li>{{ $t('servizi.servizio8') }}</li>
                <li>{{ $t('servizi.servizio9') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>

#serviziTestoIniziale{
text-align: center;
justify-content: center;
font-size: 18px;

}
#containerServizi{
line-height: 1.75em;
font-size: 18px;
text-align: start ;
}
#serviziListaConsulenza_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#serviziLista_{
justify-content: center;
display: flex;

	margin-top: 20px;
text-align: initial;

}
#container{
	margin:2rem;

}
.active
{
background: #f9f9f9;
}
.feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
	margin-left: 1rem;
	margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #fff;
    border-radius: .75rem;
}
.containerConsulenza {

margin: 1rem;
}
.containerTrading {

margin: 1rem;

}
.containerBank {

margin: 1rem;

}
.subDiv1:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv2:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.subDiv3:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#fruttiesotici{
	margin-top: 1rem;
}
#text{
	margin-top: 1.5rem;
}
</style>