<template>
  <div>
    <div class="header sticky-top ">
      <ppHeader />
    </div>

    <div id="app">
      <Cookie />
      <div
        ref="immagini"
        class="immagini"
      >
        <immagine />
      </div>
      <div
        ref="chiSiamo"
        class="chiSiamo"
      >
        <chiSiamo />
      </div>
      <div
        ref="servizi"
        class="servizi"
      >
        <div
          id="featured-3"
          class="container px-4 py-5"
        >
          <div class="title">
            <h1 class="pb-2 border-bottom lh-1 mb-3">
              <font style="vertical-align: inherit;">
                {{ $t('header_footer.servizi') }}
              </font>
            </h1>
          </div>
          <div
            id="containerServizi"
            class=""
          >
            <div ref="consulenza">
              <consulenza />
            </div>
            <div ref="trading">
              <trading />
            </div>
            <div ref="bankAdvisor">
              <bankAdvisor />
            </div>
          </div>
        </div>
        <servizi />
      </div>
      <div
        ref="formContatti"
        class="formContatti"
      >
        <formContatti />
      </div>
    </div>
    <div class="footer">
      <ppFooter />
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Cookie from '../components/cookie.vue'
import FormContatti from '../components/FormContatti.vue'
import ChiSiamo from '../components/ChiSiamo.vue'
import BankAdvisor from '../components/servizi/BankAdvisor.vue'
import Consulenza from '../components/servizi/Consulenza.vue'
import Trading from '../components/servizi/Trading.vue'
import Immagine from '../components/Immagine.vue'
export default {
 name: 'Home',
  components: {
    ppHeader: Header,
    ppFooter: Footer,
	Cookie: Cookie,
	formContatti: FormContatti,
	chiSiamo: ChiSiamo,
	bankAdvisor: BankAdvisor,
	consulenza: Consulenza,
	trading: Trading,
	immagine:Immagine
  },
  methods: {

  }
}

</script>

<style lang="scss">

#app {
	min-height: 74vh;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
.text{
	font-size:1.2rem;
}
@media (max-width: 772px){
.text{
	font-size:1.1rem;
}
}

.footer{
	margin-top: 5rem;

}
#containerServizi{
line-height: 1.75em;
font-size: 18px;
text-align: start ;
}
</style>

