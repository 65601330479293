<template>
  <div class="dropdown nav-item langPosition">
    <a
      id="dropdownMenuLink"
      class="btn btn-secondary dropdown-toggle nav-link flex items-center langDropdownButton"
      href="#"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >

      <img
        :src="`/flag_${$i18n.locale}.svg`"
        alt="flag"
        class="country-icon as-toggle flagShadow flag1"
      >

    </a>

    <ul
      class="dropdown-menu"
      aria-labelledby="dropdownMenuLink"
      @change.prevent="changeLocale"
    >
      <router-link
        v-for="locale in locales"
        :key="locale.code"
        :to="`/${locale.code}`"
        class="dropdown-item"
      >
        <a
          class="dropdown-item flex langHover"
          href="#"
        >
          <img
            :src="locale.flag"
            class="flag"
          >
          &nbsp;                <span class="locale-name">  {{ locale.name }}</span>
        </a>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/i18n/supported-locales"
// import LocalizedLink from './LocalizedLink.vue'

export default {
  data: () => ({ locales: getSupportedLocales() }),

    methods: {
    changeLocale(e) {
      const locale = e.target.value
      this.$router.push(`/${locale}`)
    }
  }
  /* ,
  computed: {
        currentLocale () {
			return this.$router.params.locale
		}
	} */
}
</script>
<style lang="scss" scoped>
// Position of language dot
.langPosition {
  // position: fixed;
  // display: flex;
  justify-content: center;

}
// Box that holds all language options
.langBox {
  user-select: none;
  padding: 5px 5px 5px -5px;
  width: max-content;
  box-shadow: 0px 3px 6px rgb(204, 210, 235);
}
// Language name text
.localLang {
  position: absolute;
  margin-top: 3px;
  font-size: 18px;
  padding-left: 15px;
  font-weight: 400;
  vertical-align: middle;
}
// Animations
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
.langDropdownButton {
  outline: none;
  cursor: pointer !important;
  background-color: transparent !important;
  border: none;
  padding-right: 0px;
}
.shortName {
  margin-top: 5px !important;
}
ul {
  text-align: left;
  min-width: 138px;
  border-radius: 8px;
  background: white;
  z-index: 500;
  position: absolute;
  list-style: none;
  padding: 10px 10px !important;
  margin: 0px !important;
  bottom: 60px;
  left: -10px;
  li {
    padding: 5px 0;
  }
}
.flag {
  width: 30px;
	height: 30px;
}
.flag1{
	width: 25px;
	height: 25px;
}
.flagShadow {
  border-radius: 30px;
  box-shadow: 0px 3px 3px rgb(204, 210, 235);
  width: 25px;
}
.langHover {
  margin: 10px;
  text-decoration: none;
  color: #222;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: #007aff;
  }
}
</style>
