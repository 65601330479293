<template>
  <div id="notfound">
    <div class="notfound-bg" />
    <div class="notfound">
      <div class="notfound-404">
        <h1>404</h1>
      </div>
      <h2>we are sorry, but the page you requested was not found</h2>
      <a
        href="https://finsuiza.ch/"
        class="home-btn"
      >Go Home</a>
    </div>
  </div>
</template>
<script>
export default {
	name: 'PathNotFound'
};
</script>
<style lang="scss" scoped>
.center {
  margin: auto;
  width: 60%;
  border: 3px solid #73AD21;
  padding: 10px;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound {
    max-width: 910px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
@media only screen and (max-width: 480px)
{.notfound .notfound-404 {
    height: 146px;
}}
.notfound .notfound-404 {
    position: relative;
    height: 200px;

}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.notfound {
    max-width: 910px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
@media only screen and (max-width: 480px)
{.notfound .notfound-404 h1 {
    font-size: 146px;
}}
@media only screen and (max-width: 767px)
{.notfound .notfound-404 h1 {
    font-size: 182px;
}}
.notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 220px;
    font-weight: 900;
    margin: 0px;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 10px;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.notfound {
    max-width: 910px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
#notfound {
    position: relative;
    height: 100vh;
}
</style>